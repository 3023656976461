import React from 'react';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
function ML() {
    return (
        <div>
            <Jumbotron className="bg-transparent jumbotron-fluid p-4">
                <h3 style={{ textAlign: "left", paddingTop: "100px"}} className="text-muted display-5 font-weight-normal">Research</h3>
                <Row className="justify-content-left py-5">
                    <Col md={11} sm={11}>
                         <p style={{ color: "#868D86", fontFamily: "Libre Franklin" }} className="text-justify font-weight-light text-muted">
                            <a href="/" style={{ color: "#14d00b", fontFamily: "Libre Franklin-Medium"}} target="_blank" rel="noreferrer noopener">CURRENT: Language Conditioned Imitation Learning using VLMs</a>
                            <br></br>
                            <div style={{color: "#868D86"}}>Using Text Embeddings as a target goal and condition Behavioral Transformers on language instructions.</div>
                        </p>
                        <br></br>
                        <p style={{ color: "#868D86", fontFamily: "Libre Franklin" }} className="text-justify font-weight-light text-muted"> 
                            <a style={{ color: "#14d00b", fontFamily: "Libre Franklin-Medium"}} href='https://mahis.life/bet/' target="_blank" rel="noreferrer noopener">Behavior Transformers: Cloning k modes with one stone</a>
                            <br></br>
                            <a style={{ color: "#868D86", fontFamily: "Libre Franklin" }} href='https://mahis.life/bet/' target="_blank" rel="noreferrer noopener">Behavior Transformers (BeT) is a new method for learning behaviors from rich, distributionally multi-modal data.</a>
                            <br></br>
                            <i>NeurIPS 2022: Neural Information Processing Systems</i>
                        </p>
                        <br></br>
                        <br></br>
                    </Col>
                </Row>
            </Jumbotron>

             {/* <Jumbotron className="bg-transparent jumbotron-fluid p-4">
                <h3 style={{ textAlign: "left", paddingTop: "100px"}} className="text-muted display-5 font-weight-normal">Notes</h3>
                <Row className="justify-content-left py-5">
                    <Col md={9} sm={12}>
                        <p style={{ color: "#868D86", fontFamily: "Libre Franklin" }} className="text-justify font-weight-light text-muted"> 
                            <a style={{ color: "#14d00b", fontFamily: "Libre Franklin-Medium"}} href='https://github.com/artyaltanzaya/' target="_blank" rel="noreferrer noopener"></a>
                        </p>
                        <br></br>
                        <p style={{ color: "#868D86", fontFamily: "Libre Franklin" }} className="text-justify font-weight-light text-muted"> 
                            <a style={{ color: "#14d00b", fontFamily: "Libre Franklin-Medium"}} href='https://github.com/artyaltanzaya/' target="_blank" rel="noreferrer noopener"> ... </a>
                        </p>
                        <br></br>
                        <p style={{ color: "#868D86", fontFamily: "Libre Franklin" }} className="text-justify font-weight-light text-muted"> 
                            <a style={{ color: "#14d00b", fontFamily: "Libre Franklin-Medium"}} href='https://github.com/artyaltanzaya/' target="_blank" rel="noreferrer noopener"> ...</a>
                        </p>
                        <br></br>
                        <br></br>
                        <br></br>                   
                    </Col>
                </Row>
            </Jumbotron> */}
            
            {/* <Portfolio portfolioLinks={portfolioLinks} ></Portfolio> */}
        </div>
        
    );
}
export default ML;