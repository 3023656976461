import React from 'react';
// eslint-disable-next-line
import ReactGA from "react-ga";  //Google Analytics
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import './App.css';
import Footer from './components/Footer';
import Homepage from './pages/Homepage';
// import Projectspage from './pages/Projectspage';
// import Resumepage from './pages/Resumepage';
import Projectpage1 from './pages/Projectpage1';
import ML from './pages/ML';
import Fun from './pages/Fun';
import Projectpage2 from './pages/Projectpage2.js';
import Minecraft from './pages/Minecraft.js';
import Notes from './pages/Notes.js';
// import Secret from './pages/Secret.js';

function initizeAnalytics() {
  ReactGA.initialize("UA-159653442-2")
  ReactGA.pageview('/home')
  ReactGA.pageview('/projects')
  ReactGA.pageview('/resume')
  ReactGA.pageview('/Project1')
  ReactGA.pageview('/Project2')
  ReactGA.pageview('/GPT3')
}

class App extends React.Component {
  
  constructor(props) {
    initizeAnalytics()
    super(props);

    this.state = {
      title: 'Arilens',
      headerLinks: [
        { title: 'Home', path: '/' },
        { title: 'Data', path: '/data' },
        { title: 'ML', path: '/ML' },
        { title: 'Fun', path: '/fun' },
        { title: 'Resume', path: '/resume' },
        // { title: 'Secret', path: '/Secret' },
      ],
      home: {
        title: '',
      },
      projects: {
        title: 'Data'
      },
      ML: {
        title: 'ML',
      },
      fun: {
        title: 'Fun',
      },
      resume: {
        title: 'Resume',
      }
      
    }
  }
  
  render() {
    return (
      <Router>
        <Container className="p-3" fluid={false}>
          <Navbar style={{ color: "#0EEF1A", paddingTop: "30px" }} bg="transparent" expand="lg">
            <Navbar.Brand style={{ color: "#0EEF1A", fontFamily: "Libre Franklin", fontSize: "22px"}} className="font-weight-bold" href="/">arty</Navbar.Brand>
            <Navbar.Toggle style={{ color: "#0EEF1A" }} className="navbar-dark" aria-controls="navbar-toggle" />
            <Navbar.Collapse style={{ color: "#0EEF1A" }} id="navbar-toggle">
              <Nav className="ml-auto hover:underline">
                {/* <Link style={{ color: "#6c757d", fontFamily: "Libre Franklin" }} className="display-7 font-weight-lighter nav-link" to="/"> &nbsp;&nbsp; Home <span>&nbsp;</span></Link> */}
                <Link style={{ fontFamily: "Libre Franklin-Medium", color: "#14d00b"}} className="h5 pt-4 mx-4 nav-link hover:text-white" to="/ML">Research</Link>
                <Link style={{ fontFamily: "Libre Franklin-Medium", color: "#14d00b"}} className="h5 pt-4 mx-4 nav-link" to="/fun"> Projects</Link>
                <Link style={{ fontFamily: "Libre Franklin-Medium", color: "#14d00b"}} className="h5 pt-4 nav-link mx-4" to="/data">Notes</Link>
                {/* <Link style={{ color: "#0e0e0e", fontFamily: "Libre Franklin-Medium" }} className="h5 pt-4 nav-link" to="/resume"> <span>&nbsp;&nbsp;&nbsp;</span> CV </Link> */}
              </Nav>
            </Navbar.Collapse>
          </Navbar>
          <Route path="/" exact render={() => <Homepage title={this.state.home.title} subTitle={this.state.home.subTitle} text={this.state.home.text} />} />
          <Route path="/data" render={() => <Notes title={this.state.projects.title} subTitle={this.state.projects.subTitle} text={this.state.projects.text} />} />
          {/* <Route path="/resume" render={() => <Resumepage title={this.state.resume.title} subTitle={this.state.resume.subTitle} text={this.state.resume.text} />} /> */}
          <Route path="/ML" render={() => <ML title={this.state.resume.title} subTitle={this.state.resume.subTitle} text={this.state.resume.text} />} />
          <Route path="/Fun" render={() => <Fun title={this.state.resume.title} subTitle={this.state.resume.subTitle} text={this.state.resume.text} />} />
          <Route path="/project1" render={() => <Projectpage1 title={this.state.resume.title} subTitle={this.state.resume.subTitle} text={this.state.resume.text} />} />
          <Route path="/project2" render={() => <Projectpage2 title={this.state.resume.title} subTitle={this.state.resume.subTitle} text={this.state.resume.text} />} />
          <Route path="/Minecraft" render={() => <Minecraft title={this.state.resume.title} subTitle={this.state.resume.subTitle} text={this.state.resume.text} />} />
          {/* <Route path="/Secret" render={() => <Secret title={this.state.resume.title} subTitle={this.state.resume.subTitle} text={this.state.resume.text} />} /> */}
          <Footer />
        </Container>
      </Router>
      

    );
  }
}
export default App;