import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './fonts/Libre_Franklin/static/LibreFranklin-Thin.ttf';
import 'bootstrap/dist/css/bootstrap.css';
import './assets/css/bootstrap.min.css';
import './assets/css/agency.min.css'
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorker.register();