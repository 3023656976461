import React from 'react';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import gpt from '../assets/img/gpt.png';

function Fun() {
    // Handling Scroll
    return (
        <div>
            <Jumbotron className="bg-transparent jumbotron-fluid p-4">
                <div style={{ textAlign: "center", paddingTop: "60px", paddingLeft: '100px', paddingRight: '100px'}} className="text-muted display-5 font-weight-normal"></div>
                <Row className="justify-content-center">
                    <Col md={16} sm={9}>
                        <br></br>
                        <h4 style={{ textAlign: "left", color: "#14d00b"}} className="display-5 font-weight-normal">Space Plant: Efficient Large-Scale Plant Recognition</h4>
                        <p style={{ color: "#14d00b", fontFamily: "Libre Franklin"}}> High Performance Machine Learning</p>
                        <p style={{ color: "#868D86", fontFamily: "Libre Franklin", paddingTop: "30px"}}>Built plant recognition model using EfficientNet, ViT (Vision Transformer) and DenseNet</p>
                    </Col>
                    <Col md={16} sm={9}>
                        <br></br>
                        <h4 style={{ textAlign: "left", color: "#14d00b"}} className="display-5 font-weight-normal">6DoF Viewport Prediction of 360 Degree Videos Using Viewer Trajectories for Computer Vision</h4>
                        <p style={{ color: "#14d00b", fontFamily: "Libre Franklin"}}> Image and Video Processing</p>
                        <p style={{ color: "#868D86", fontFamily: "Libre Franklin", paddingTop: "30px"}}>Developed LSTM model and Transformer model that is specific to 6 degree-of-freedom content that can
                        predict the body and head movements of a viewer accurately using the past body and head motion trajectories. I used publicly available 6DoF user navigation dataset of 26 participants watching 150
                        looped frames of dynamic point cloud content in the Unity game engine. The dataset is comprised of head and body motion trajectories. The user’s coordinates and
orientation of their field-of-viewport is recorded and collected at each rendered frame.</p>
                    </Col>
                    <Col md={16} sm={9}>
                        <br></br>
                       <h4 style={{ textAlign: "left", color: "#14d00b"}} className="display-5 font-weight-normal">Google Landmark Recognition 2021: Benchmark for Instance-Level Recognition</h4>
                        {/* <p style={{ color: "#14d00b", fontFamily: "Libre Franklin"}}>Deep Learning</p> */}
                        <p style={{ color: "#868D86", fontFamily: "Libre Franklin", paddingTop: "30px" }}>Implemented ResNet to compete in feature extraction model for Google Landmark Retrieval 2021 Competition</p>
                    </Col>
                    <Col md={16} sm={9}>
                        <br></br>
                       <h4 style={{ textAlign: "left", color: "#14d00b"}} className="display-5 font-weight-normal">minGPT Character based Creative Chatbot</h4>
                        {/* <p style={{ color: "#14d00b", fontFamily: "Libre Franklin"}}>ECE-GY 6143 Machine Learning</p> */}
                        <p style={{ color: "#868D86", fontFamily: "Libre Franklin", paddingTop: "30px" , paddingBottom: "30px"}}>Trained miniGPT, an autoregressive Natural Language Processing (NLP) transformer model to build a chatbot in the few-shot setting that answers philosophical questions based on literature writings.</p>
                        <img className="mb-5" alt="" src={gpt} style={{width: 650}}></img>
                        <br/>
                        <p style={{ color: "#1268A5", fontFamily: "Libre Franklin" }} className="mb-0 text-left mt-5">ML Skills: PyTorch, Python, OpenAI Gym, Tensorflow, CUDA, OpenCV, Linux</p>
                        <p style={{ color: "#1268A5", fontFamily: "Libre Franklin" }} className="mb-0 text-left mt-3">Development: ReactJS, Javascript</p>
                    </Col>
                </Row>
            </Jumbotron>
       </div>
        
    );
}
export default Fun;