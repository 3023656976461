import React from 'react';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import grid from '../pages/grid.css';
// import mi1 from '../assets/img/6.jpg';
import ab from '../assets/img/abs.png';
import dd from '../assets/img/apt.png';
import nyu from '../assets/img/mal.png';
import abs from '../assets/img/fff.png';

function Fun() {
    // Handling Scroll
    return (
        <div>
            <Jumbotron className="bg-transparent jumbotron-fluid p-4">
                <h3 style={{ textAlign: "left", paddingTop: "100px"}} className="text-muted display-5 font-weight-normal">CS/Eng</h3>
                <Row className="justify-content-left py-5">
                    <Col md={16} sm={12}>
                        <br></br>
                        <div class="py-2">
                        <div class="row">
                        <div style={{cursor: 'pointer'}} class="col-lg-6 mb-3 mb-lg-0">
                            <a href="/Minecraft">
                            <div class="hover hover-1 text-white rounded"><img src={dd} alt=""/>
                            <div class="hover-overlay"></div>
                            <div class="hover-1-content px-5 py-4">
                                <h4 style={{ color: "#ffffff", fontFamily: "Libre Franklin", textAlign: "left"}} class="hover-1-title mb-0">
                                    <span class="font-weight-light">PCGRL for Building 3D Artifacts in Minecraft </span></h4>
                                <p style={{ color: "#ffffff", fontFamily: "Libre Franklin"}} class="hover-1-description mb-0">Procedural Content Generation via Reinforcement Learning (PCGRL)</p>
                                {/* <p style={{ color: "#ffffff", fontFamily: "Libre Franklin" }} className="hover-1-description mb-0 text-left text-info"><small>PyTorch, Python, OpenAI Gym</small></p> */}
                                <p style={{ backgroundColor: "#14d00b", color: "black", fontFamily: "Libre Franklin-Medium" }} className="hover-1-description mb-0 mt-4 text-left text-dark bg-[#14d00b]"><small>&nbsp;PyTorch, Python, OpenAI Gym&nbsp;</small></p>
                                 {/* <p style={{ color: "#ffffff", fontFamily: "Libre Franklin" }} className="text-left text-muted font-weight-lighter"><small>PyTorch, Tensorflow, Python</small></p> */}
                                
                            </div>
                            </div></a>
                        </div>
                        <br></br>
                        <br></br>
                        <div style={{cursor: 'pointer'}} class="col-lg-6 mb-3">
                           <a href="/project1">
                           <div class="hover hover-1 text-white rounded"><img src={nyu} alt=""/>
                           <div class="hover-overlay"></div>
                            <div class="hover-1-content px-5 py-4">
                               <h4 style={{ color: "#ffffff", fontFamily: "Libre Franklin", textAlign: "left"}} class="hover-1-title mb-0">
                                   <span class="font-weight-light">ML & Software Projects</span></h4>
                                   <p style={{ backgroundColor: "#14d00b", color: "black", fontFamily: "Libre Franklin-Medium" }} className="hover-1-description mb-0 mt-4 text-left text-dark bg-[#14d00b]"><small>&nbsp;PyTorch, Python, OpenAI Gym, Javascript, ReactJS, CUDA, OpenCV&nbsp;</small></p>
                               <p class="hover-1-description font-weight-light mb-0"></p>
                            </div>
                            </div>
                            </a>
                        </div>
                        <br></br>
                        <br></br>
                        {/* <div class="col-lg-6 mb-5">
                            <div class="hover hover-1 text-white rounded"><img src={mi1} alt=""/>
                            <div class="hover-overlay"></div>
                            <div class="hover-1-content px-5 py-4">
                               <h4 style={{ color: "#ffffff", fontFamily: "Libre Franklin", textAlign: "left"}} class="hover-1-title mb-0"> <span class="font-weight-light">Caption</span></h4>
                                <p class="hover-1-description font-weight-light mb-0">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
                            </div>
                            </div>
                        </div>
                        <br></br>
                        <br></br>
                        <div class="col-lg-6 mb-3 mb-lg-0">
                            <div class="hover hover-1 text-white rounded"><img src={mi1} alt=""/>
                            <div class="hover-overlay"></div>
                            <div class="hover-1-content px-5 py-4">
                                <h4 style={{ color: "#ffffff", fontFamily: "Libre Franklin", textAlign: "left"}} class="hover-1-title mb-0">
                                    <span class="font-weight-light">Caption</span></h4>
                                <p style={{ color: "#ffffff", fontFamily: "Libre Franklin"}} class="hover-1-description mb-0">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
                            </div>
                            </div>
                        </div> */}
                        </div>
                    </div>
                    </Col>
                </Row>
            </Jumbotron>
             <Jumbotron className="bg-transparent jumbotron-fluid p-4">
                <h3 style={{ textAlign: "left"}} className="text-muted display-5 font-weight-normal">& more</h3>
                <Row className="justify-content-left py-5">
                    <Col md={16} sm={12}>
                        <br></br>
                        <div class="py-2">
                        <div class="row">
                        <div style={{cursor: 'pointer'}} class="col-lg-6 mb-3 mb-lg-0" >
                            <a href="https://www.absurd.nyc/"><div class="hover hover-1 text-white rounded"><img src={abs} alt=""></img>
                            <div class="hover-overlay"></div>
                            <div class="hover-1-content px-5 py-5">
                                <h4 style={{ color: "#ffffff", fontFamily: "Libre Franklin", textAlign: "left"}} class="hover-1-title mb-0">
                                    <a class="font-weight-light" style={{ color: "#ffffff", fontFamily: "Libre Franklin", textDecoration: "none"}}  href="https://absurdum.vercel.app/" target="_blank" rel="noreferrer">Absurd</a></h4>
                                <p style={{color: "#ffffff", fontFamily: "Libre Franklin"}}
                                class="hover-1-description mb-0">Democratized community for artists</p>
                                <p style={{ backgroundColor: "#14d00b", color: "black", fontFamily: "Libre Franklin-Medium" }} className="hover-1-description mb-0 mt-4 text-left text-dark bg-[#14d00b]"><small>&nbsp;NextJS, ReactJS, MongoDB, GraphQL, Node.js, Tailwind, Apollo, &nbsp;PostgreSQL&nbsp;</small></p> 
                            </div>
                            </div></a>
                        </div>
                        <br></br>
                        <br></br>
                        <div style={{cursor: 'pointer'}} class="col-lg-6 mb-3 mb-lg-0" >
                            <a href="https://absurdum.vercel.app/"><div class="hover hover-1 text-white rounded"><img src={ab} alt=""></img>
                            <div class="hover-overlay"></div>
                            <div class="hover-1-content px-5 py-5">
                                <h4 style={{ color: "#ffffff", fontFamily: "Libre Franklin", textAlign: "left"}} class="hover-1-title mb-0">
                                    <a class="font-weight-light" style={{ color: "#ffffff", fontFamily: "Libre Franklin", textDecoration: "none"}}  href="https://absurdum.vercel.app/" target="_blank" rel="noreferrer">Philosign</a></h4>
                                    
                                <p style={{ color: "#ffffff", fontFamily: "Libre Franklin"}}
                                class="hover-1-description mb-0"> Philosopher's 8 Ball</p>
                                <p style={{ backgroundColor: "#14d00b", color: "black", fontFamily: "Libre Franklin-Medium" }} className="hover-1-description mb-0 mt-4 text-left text-dark bg-[#14d00b]"><small>&nbsp;ReactJS, PostgreSQL, HTML/CSS&nbsp;</small></p> 
                            </div>
                            </div></a>
                        </div>
                        {/* <div class="col-lg-6 mb-3">
                            <div class="hover hover-1 text-white rounded"><img src={ab} alt=""/>
                            <div class="hover-overlay"></div>
                            <div class="hover-1-content px-5 py-4">
                                <h3 style={{ color: "#ffffff", fontFamily: "Libre Franklin", textAlign: "left"}} class="hover-1-title text-uppercase mb-0"> <span class="font-weight-light">Landmark </span>Caption</h3>
                                <p class="hover-1-description font-weight-light mb-0">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
                            </div>
                            </div>
                        </div>
                        <br></br>
                        <br></br>
                        <div style={{paddingTop: "100px"}} class="col-lg-6 mb-3 mb-lg-0">
                            <div class="hover hover-1 text-white rounded"><img src={ab} alt=""/>
                            <div class="hover-overlay"></div>
                            <div class="hover-1-content px-5 py-4">
                                <h3 style={{ color: "#ffffff", fontFamily: "Libre Franklin", textAlign: "left"}} class="hover-1-title mb-0"> <span class="font-weight-light">PCGRL for Building 3D Artifacts in Minecraft </span></h3>
                                <p style={{ color: "#ffffff", fontFamily: "Libre Franklin"}} class="hover-1-description mb-0">Procedural Content Generation via Reinforcement Learning (PCGRL)</p>
                            </div>
                            </div>
                        </div>
                        <div style={{paddingTop: "100px"}} class="col-lg-6 mb-3">
                            <div class="hover hover-1 text-white rounded"><img src={ab} alt=""/>
                            <div class="hover-overlay"></div>
                            <div class="hover-1-content px-5 py-4">
                                <h3 class="hover-1-title text-uppercase mb-0"> <span class="font-weight-light">Image </span>Caption</h3>
                                <p class="hover-1-description font-weight-light mb-0">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
                            </div>
                            </div>
                        </div> */}
                        </div>
                    </div>
                    </Col>
                </Row>
            </Jumbotron>
            
        </div>
        
    );
}
export default Fun;