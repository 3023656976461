import React from 'react';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import grid from '../pages/grid.css';
import ccd from '../assets/img/ccd.png';
import bb from '../assets/img/bb.png';
import apt from '../assets/img/apt.png';
import grid from '../pages/grid.css';
import div from '../assets/img/div.png';
import mm from '../assets/img/gif.gif';
import house from '../assets/img/house.png';
import h from '../assets/img/h.png';
import s from '../assets/img/s.png';

function Fun() {
    // Handling Scroll
    return (
        <div>
            <Jumbotron className="bg-transparent jumbotron-fluid p-4">
                <h4 style={{ textAlign: "center", paddingTop: "100px", paddingLeft: '100px', paddingRight: '100px', color: "#868D86"}} className="display-5 font-weight-normal">Procedural Content Generation via Reinforcement Learning (PCGRL) for Building 3D Artifacts in Minecraft</h4>
                <Row className="justify-content-center py-5">
                    <Col md={16} sm={9}>
                        <br></br>
                        <p style={{ fontFamily: "Libre Franklin", color: "#868D86" }}>The goal in using PCGRL is to explore how we can train an agent to build structured 
                        artifacts in the 3D environment. This approach compared to PCGML, does not use the pre-existing dataset to train from, instead relies heavily on the 
                        reward function to learn how to take the next action. I experimented with different reward functions to build a house structure using a RL agent.
                        First, I experimented with having an initial state of a square of blocks and rewarded if the agent diversified the blocks and added noise to the building. 
                        This resulted in diverse types of blocks that were not structured.</p>
                        <Row className="justify-content-center py-5">
                        <img alt="" src={div} style={{width: 650}}></img>
                        </Row>
                        <p style={{fontFamily: "Libre Franklin", color: "#868D86" }}>I experimented with using an agent to traverse from ground level to reach a higher level 
                        to build floors from the ground and the agent was optimized to make diagonal lines from the two points and I tested functions that optimize creating walls around a certain coordinate, however, of course walls do not qualify as a house. </p>
                        <Row className="justify-content-center py-5">
                        <img alt="" src={mm} style={{width: 650}}></img>
                        </Row>
                        <p style={{ fontFamily: "Libre Franklin", color: "#868D86" }}>For the research, the structure was a priority so I started aiming to build symmetrical and structured blocks as shown below.  </p>
                        <Row className="justify-content-center">
                        <link href={grid} />
                        <div className="gallery">
                            <div className="gallery__item gallery__item--2">
                                <img src={ccd} alt="Gallery 3" className="gallery__img" style={{width: 400}} />
                            </div>
                            <div className="gallery__item gallery__item--3">
                                <img src={bb} alt="Gallery 4" className="gallery__img" style={{width: 400}} />
                            </div>
                            <div className="gallery__item gallery__item--4">
                                <img src={house} alt="Gallery 5" className="gallery__img" style={{width: 400}} />
                            </div>
                            <div className="gallery__item gallery__item--5">
                                <img src={h} alt="Gallery 6" className="gallery__img" style={{width: 400}} />
                            </div>
                        </div></Row>
                        <p style={{ fontFamily: "Libre Franklin", paddingTop: "60px", color: "#868D86" }}>Afterward, I prioritized house heights, and certain blocks that are suitable for house material and 
                        added some noise to the training. This gave some structured shapes. </p>
                        <Row className="justify-content-center">
                        <link href={grid} />
                        <div className="gallery">
                            <div className="gallery__item gallery__item--2">
                                <img src={apt} alt="Gallery 3" className="gallery__img" style={{width: 400}} />
                            </div>
                            <div className="gallery__item gallery__item--3">
                                <img src={s} alt="Gallery 4" className="gallery__img" style={{width: 400}} />
                            </div>
                        </div></Row>
                        <p style={{ fontFamily: "Libre Franklin", paddingTop: "60px", color: "#868D86" }}>For training, I used an OpenAI Gym interface and Proximal Policy Optimization (PPO) to train the agents. 
                        In the PCGRL environment, my current representation space allowed the agent to take action only in the current location so it would be fun to experiment with 
                        using a representation space where the agent has full control over the location and block type which would allow for more creative results.  Overall, this was a fun project to learn how Online RL agents work in 3D environment
                        with minimal computing power.</p>
                        {/* <p style={{ color: "#6c757d", fontFamily: "Libre Franklin", paddingTop: "60px" }} className="mt-5">Skills:</p> */}
                        <p style={{ color: "#14d00b", fontFamily: "Libre Franklin" }} className="mb-0 text-left mt-5">Skills: PyTorch, Python, OpenAI Gym</p>
                        <p style={{ color: "#6c757d", fontFamily: "Libre Franklin", paddingTop: "60px" }}>References:</p>
                        <p style={{ color: "#6c757d", fontFamily: "Libre Franklin" }} className="text-justify font-weight-light text-muted">
                            <a style={{ color: "#14d00b", fontFamily: "Libre Franklin-Medium"}} href='https://github.com/smearle/control-pcgrl' target="_blank" rel="noreferrer noopener">PCGRL</a>
                        </p>
                        <p style={{ color: "#6c757d", fontFamily: "Libre Franklin" }} className="text-justify font-weight-light text-muted">
                            <a style={{ color: "#14d00b", fontFamily: "Libre Franklin-Medium"}} href='https://github.com/real-itu/Evocraft-py' target="_blank" rel="noreferrer noopener">Evocraft-py</a>
                        </p>
                    
                    </Col>
                </Row>
            </Jumbotron> 
        </div>
        
    );
}
export default Fun;