import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
function Footer() {
    return (
        <footer className="mt-5">
            <Container fluid={true}>
                <Row>
                    <Col className="p-0" md={0} sm={0}>
                    </Col>
                    <Col className="p-0 d-flex justify-content-end" md={0}>
                    </Col>
                </Row>
            </Container>
        </footer>
    );
}
export default Footer;