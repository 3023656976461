import React from 'react';
import grid from '../pages/grid.css';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import survey from '../assets/img/survey.png';
import survey2 from '../assets/img/survey2.png';
import survey4 from '../assets/img/survey4.png';
import survey7 from '../assets/img/survey7.png';
function Projectpage2(props) {
    return (
        <div>
            <Jumbotron className="bg-transparent jumbotron-fluid p-4">
                <Row className="justify-content-center py-5">
                    <Col md={9} sm={12}>
                        <h3 className="text-muted display-5 font-weight-normal">User Survey:</h3>
                        <h3 className="text-muted display-5 font-weight-normal">Use and Perception of Columbus Square Park</h3>
                        <br></br>
                        <p style={{ color: "#6c757d", fontFamily: "Libre Franklin" }} className="display-7 font-weight-lighter">The Lindy Institute for Urban Innovation conducted a user study on Columbus Square Park in anticipation of the park’s redesign. The research investigated residents and how they used the park, what they thought about the park and its amenities, and changes they’d like to see in the park. Following the renovation, a study was conducted to investigate user behavior and opinion towards the renovated park. This report documents methodology, findings, and analysis of the study conducted prior to the park’s redesign. </p>
                        <p style={{ color: "#6c757d", fontFamily: "Libre Franklin" }} className="display-7 font-weight-lighter"> Our team conducted a pre-construction analysis and collected data by field and online survey. Survey results indicate that the majority of respondents were between 35 years old and 50 years old (54%) and 18 and 34 years old (46.34%). As illustrated in the diagram, more than half of respondents (57%) reside in the 19147 zip code, in which the park is located. Another significant percentage (21%) resides in the 19148 area, which is adjacent to the park neighborhood. </p>
                    </Col>
                </Row>
            </Jumbotron>
            <link href={grid} />
            <div className="gallery">
                <div className="gallery__item gallery__item--2">
                    <img src={survey} alt="Gallery 1" className="gallery__img" style={{width: 500}}/>
                </div>
                <div className="gallery__item gallery__item--3">
                    <img src={survey2}  alt="Gallery 1" className="gallery__img" style={{width: 500}}/>
                </div>
                {/* <div className="gallery__item gallery__item--4">
                    <img src={survey3} alt="Gallery 5" className="gallery__img" style={{width: 500}}/>
                </div> */}
                <div className="gallery__item gallery__item--5">
                    <img src={survey4} alt="Gallery 5" className="gallery__img" style={{width: 500}}/>
                </div>
                <div className="gallery__item gallery__item--4">
                    <img src={survey7} alt="Gallery 6" className="gallery__img" style={{width: 500}}/>
                </div>
            </div>
        </div>
    );
}
export default Projectpage2;