import React from 'react';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import resume from '../assets/images/a8.png'
import cv from '../assets/img/ml.pdf'

function Homepage(props) {
    return (
        <div>
 
        <div>
            <Jumbotron className="bg-transparent jumbotron-fluid p-6">
            <Container fluid={true}>
                <Row className="justify-content-center">
                    <Col md={14} sm={12}>
                        <img src={resume} className="img-fluid" style={{width: "40%"}} alt="d"/>
                        <br></br>
                        <br></br>
                        <br></br>
                        {/* <h3 style={{ fontFamily: "Libre Franklin", lineHeight: "3", color: "#14d00b"}} className="text-left display-5 font-weight-bold" >I'm Arty. </h3> */}
                        <h6 style={{ fontFamily: "Libre Franklin", lineHeight: "3", fontSize: "17px", color: "#14d00b"}} className="text-left font-weight-light">
                            <p style={{ textDecoration: "line-through", textDecorationThickness: "1px"}}>Graduated from @NYU CS.</p>
                            <span style={{fontFamily: "Libre Franklin-SemiBold", color: "#14d00b"}}> </span>
                            <span style={{ttextDecorationThickness: "1px"}}>Researched</span><span style={{textDecoration: "none", fontFamily: "Libre Franklin-SemiBold", color: "#14d00b"}}> RL/Imitation Learning </span> 
                            <span style={{ textDecorationThickness: "1px"}}>@CILVRLab NYU Computational Intelligence, Learning,</span><span style={{fontFamily: "Libre Franklin-SemiBold", color: "#14d00b"}}> Vision, and Robotics
                            </span>
                            <br/>+ <span style={{textDecorationThickness: "1px"}}>I built</span>
                            
                            <a style={{ fontFamily: "Libre Franklin-SemiBold", color: "#14d00b"}} href="https://www.absurd.nyc"> Absurd.</a>
                            <br/>
                            + built <a style={{ fontFamily: "Libre Franklin-SemiBold", color: "#14d00b", textDecoration: "line-through"}} href="https://www.ghostai.co">GhostAI.</a>
                            <br/>
                            + building 
                            <a style={{ fontFamily: "Libre Franklin-SemiBold", color: "#14d00b"}} href="https://www.antispace.sh"> Antispace (aka previously GhostAI).</a>
                            {/* <a style={{ color: "#3B8AC3", fontFamily: "Libre Franklin-Medium", lineHeight: "3"}} href='/Fun'>projects</a>. */}
                            {/* <a> Most of the time, I am learning and experimenting with new&nbsp;</a>
                            <a style={{ color: "#3B8AC3", fontFamily: "Libre Franklin-Medium", lineHeight: "3"}} href='/Fun'>projects</a>. */}
                            {/* <a> You can find my resume&nbsp;</a> */}
                            {/* <a style={{ color: "#3B8AC3", fontFamily: "Libre Franklin-Medium", lineHeight: "3"}} href={cv} target="_blank" rel="noreferrer">here</a>. */}
                        </h6>
                        <br></br>
                        <br></br>
                    </Col>
                </Row>
            </Container>
        </Jumbotron>
            <footer className="footer">
                 <div className="container">
                     <div className="row align-items-center">
                         <div className="col-md-4">
                         </div>
                         <div className="col-md-4">
                             <ul className="list-inline social-buttons">
                                 <li className="list-inline-item">
                                     <a style={{backgroundColor: "black"}} href="mailto:aa4928@nyu.edu" target="_blank" rel="noopener noreferrer">
                                         <i style={{color: "#14d00b"}} className="fa fa-envelope-o"></i>
                                     </a>
                                 </li>
                                 <li className="list-inline-item">
                                     <a style={{backgroundColor: "black"}} href="https://github.com/artyaltanzaya">
                                         <i style={{color: "#14d00b"}} className="fa fa-github"></i>
                                     </a>
                                 </li>
                                 <li className="list-inline-item">
                                     <a style={{backgroundColor: "black"}} href="https://www.linkedin.com/in/artyariuntuya/">
                                         <i style={{color: "#14d00b"}} className="fa fa-linkedin"></i>
                                     </a>
                                 </li>
                             </ul>
                         </div>
                         <div className="col-md-4">
                             <ul className="list-inline quicklinks">
                                 <li className="list-inline-item">
                                 </li>
                                 <li className="list-inline-item">
                                 </li>
                             </ul>
                         </div>
                     </div>
                 </div>
             </footer>
             <p style={{ color: "#14d00b", fontFamily: "Libre Franklin" }} className="text-left text-muted font-weight-lighter"><small style={{ color: "#14d00b", fontFamily: "Libre Franklin" }} >&nbsp;&nbsp; &copy; arty</small></p>
        </div>
        </div>
    );
}
export default Homepage;