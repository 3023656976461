import React from 'react';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
function ML() {
    return (
        <div>
             <Jumbotron className="bg-transparent jumbotron-fluid p-4">
                <h3 style={{ textAlign: "left", paddingTop: "100px"}} className="text-muted display-5 font-weight-normal">Notes</h3>
                <Row className="justify-content-left py-5">
                    <Col md={9} sm={12}>
                        <p style={{ color: "#6c757d", fontFamily: "Libre Franklin" }} className="text-justify font-weight-light text-muted"> 
                            <a style={{ color: "#14d00b", fontFamily: "Libre Franklin-Medium"}} href='https://github.com/artyaltanzaya/' target="_blank" rel="noreferrer noopener"></a>
                        </p>
                        <br></br>
                        <p style={{ color: "#6c757d", fontFamily: "Libre Franklin" }} className="text-justify font-weight-light text-muted"> 
                            <a style={{ color: "#14d00b", fontFamily: "Libre Franklin-Medium"}} href='https://github.com/artyaltanzaya/' target="_blank" rel="noreferrer noopener"> ... </a>
                        </p>
                        <br></br>
                        <p style={{ color: "#6c757d", fontFamily: "Libre Franklin" }} className="text-justify font-weight-light text-muted"> 
                            <a style={{ color: "#14d00b", fontFamily: "Libre Franklin-Medium"}} href='https://github.com/artyaltanzaya/' target="_blank" rel="noreferrer noopener"> ...</a>
                        </p>
                        <br></br>
                        <br></br>
                        <br></br>                   

                    </Col>
                </Row>
            </Jumbotron>
            
            {/* <Portfolio portfolioLinks={portfolioLinks} ></Portfolio> */}
        </div>
        
    );
}
export default ML;